import { Link } from "react-router-dom";
import styles from  "./Privacy.module.css";
import logo from "../../assets/images/crosstailsroundlogo.png"
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const Privacy = () => {
    const {pathname} = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    },[pathname]);

  return (
    <div className={styles.container}>
        <div><Link to="/"><img src={logo} className={styles.logo} alt="Crosstails Logo"/></Link></div>
        <h1 className={styles.header}>Privacy Policy</h1>
          <div className={styles.infoContainer}> 
              <p className={styles.info}>
                  If you require any more information or have any questions about our privacy policy, please feel free to contact us by email at <a className={styles.link}href="mailto:giavannahampton@crosstails.com"><span className={styles.linkText}>giavannahampton@crosstails.com</span></a><br/>
              </p>
              <p className={styles.info}>
                  Cross Tails LLC, or any websites and applications associated with Cross Tails LLC, we consider the privacy of our visitors to be extremely important. This privacy policy document describes in detail the types of personal information collected and recorded by Cross Tails LLC, or any websites associated with Cross Tails LLC and how we use it.<br/>
              </p>
              <p className={styles.subHeader}>
                  Log Files<br/>
              </p>
              <p className={styles.info}>
                  Like many other Web sites, Cross Tails LLC, or any websites associated with Cross Tails LLC may make use of log files. These files merely logs visitors to the site – usually a standard procedure for hosting companies and a part of hosting services' analytics. The information inside the log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date/time stamp, referring/exit pages, and possibly the number of clicks. This information is used to analyze trends, administer the site, track user’s movement around the site, and gather demographic information. IP addresses, and other such information are not linked to any information that is personally identifiable. <br/>
              </p>

              <p className={styles.info}>
                  Cross Tails app stores crash and failure data which is used to improve our product. This crash and failure data is not linked to any information that is personally identifiable.<br/>
              </p>

              <p className={styles.subHeader}>
                  Cookies and Web Beacons <br/>
              </p>  

              <p className={styles.info}>
                  Cross Tails LLC, or any websites associated with Cross Tails uses cookies to store information about visitors’ preferences, to record user-specific information on which pages the site visitor accesses or visits, and to personalize or customize our web page content based upon visitors’ browser type or other information that the visitor sends via their browser. <br/>
              </p>  

              {/* <p className={styles.subHeader}>
                  Our Advertising Partners <br/>
              </p>  

              <p className={styles.info}>
                  Some of our advertising partners may use cookies and web beacons on our site. Our advertising partners include (ENTER PARTNERS)<br/>
              </p>  

              <p className={styles.info}>
                  While each of these advertising partners has their own Privacy Policy for their site, an updated and hyperlinked resource is maintained here: (ENTER HYPERLINK) <br/>
              </p> */}

              <p className={styles.subHeader}>
                  Children’s Information<br/>
              </p>

              <p className={styles.info}>
                  We believe it is important to provide added protection for children online. We encourage parents and guardians to spend time online with their children to observe, participate in and/or monitor and guide their online activity. <br/>
              </p>


              <p className={styles.info}>
                  Cross Tails LLC, or any websites associated with Cross Tails LLC does not knowingly collect any personally identifiable information from children under the age of 13. If a parent or guardian believes that Cross Tails LLC, or any websites associated with Cross Tails LLC has in its database the personally-identifiable information of a child under the age of 13, please contact us immediately (using the contact in the first paragraph) and we will use our best efforts to promptly remove such information from our records. <br/>
              </p>


              <p className={styles.subHeader}>
                  Online Privacy Policy Only <br/>
              </p>


              <p className={styles.info}>
                  This privacy policy applies only to our online activities and is valid for visitors to our website and regarding information shared and/or collected there and or all websites/opt-in forms produced by Cross Tails LLC. <br/>
              </p>


              <p className={styles.info}>
                  This policy does not apply to any information collected offline. <br/>
              </p>


              <p className={styles.subHeader}>
                  Consent<br/>
              </p>


              <p className={styles.info}>
                  By using our website, you hereby consent to our privacy policy and agree to its terms. <br/>
              </p>

              <p className={styles.subHeader}>
                  Update<br/>
              </p>

              <p className={styles.info}>
                  This Privacy Policy was last updated on: February 2, 2024. <br/>
              </p>

              <p className={styles.info}>
                  Should we update, amend or make any changes to our privacy policy, those changes will be posted here. <br/>
              </p>
      </div>
    </div>
  );
};
export default Privacy;
