import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/crosstailsroundlogo.png";

const Footer = () =>{

    const dateYear = new Date().getFullYear();

    return(
        <div className={styles.container}>
            <div>
                {dateYear == "2024" ? (
                <p className={styles.infoText}>&#169; 2024 Cross Tails LLC</p>) : (
                <p className={styles.infoText}> &#169; 2024 - {dateYear} Cross Tails LLC</p>)}
            </div>
            <div><Link to="/"><img src={logo} className={styles.logo} alt="Crosstails Logo"/></Link></div>
            <div className={styles.linkContainer} >
                <div ><Link to="/privacy" className={styles.links}> <p className={styles.linkText}>Privacy Policy</p></Link></div>
                <div ><Link to="/terms" className={styles.links}><p className={styles.linkText}>Terms and Conditions</p></Link></div>
                <div ><Link to="/disclaimer" className={styles.links}><p className={styles.linkText}>Disclaimer</p></Link></div>
                <div><a href="mailto:giavannahampton@crosstails.com" className={styles.links}><p className={styles.linkText}>Contact Us</p></a></div>
            </div>
        </div>
    );
};
export default Footer;

