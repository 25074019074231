import { useRouteError } from "react-router-dom";
import styles from "./ErrorPage.module.css";
const ErrorPage = () => {
  const error = useRouteError();
  return (
    <div className={styles.container}>
      <h1 className={styles.titleText}>Oops!</h1>
      <p className={styles.infoText}>An unexpected error has occurred.</p>
      <p className={styles.infoText}>{error.statusText || error.message}</p>
    </div>
  );
};
export default ErrorPage;
