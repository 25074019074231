import { IconContext } from "react-icons";
import logo from "../../assets/images/crosstailslogo.png";
import { FaFacebookF } from "react-icons/fa";
import styles from "./Home.module.css";
import { FaTiktok } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaSnapchatGhost } from "react-icons/fa";
import { FaGooglePlay } from "react-icons/fa";
import { IoLogoAppleAppstore } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";

const Home = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.container}>
      <div className={styles.gridContainer}>
        <div className={styles.logoContainer}>
          <img src={logo} className={styles.logo} alt="Crosstails Logo" />
          <div className={styles.button}>
            <a className={styles.buttonLink} href="#download">
              <span className={styles.buttonText}>Download App</span>
            </a>
          </div>
        </div>
        <div className={styles.mission}>
          <p className={styles.missionText}>
            Igniting a world where every pet thrives, one safe meal at a time.
          </p>
        </div>
        <div className={styles.info}>
          <p className={styles.infoText}>
            My name is Giavanna (Gia) Hampton, C.E.O of Cross Tails LLC. If you
            are a pet owner who is constantly looking for ways to make your fur
            baby’s life fulfilling, you’ve come to the right place. Looking for
            quick, accurate responses on what human food to give to your furry
            friend? Welcome to Cross Tails!
          </p>
          <p className={styles.infoText}>
            You've probably looked at your dinner scraps and wondered if you can
            feed it to your companion. You search online in hopes of identifying
            an accurate response, but just end up wasting your time without a
            solid answer.
          </p>
          <p className={styles.infoText}>
            You just want a “yes” or a “no”, right?
          </p>
          <p className={styles.infoText}>
            Well… me too. That's why I've built an app specifically for this
            issue. The Cross Tails app compiles research related to veterinary
            science and animal dietary needs to bring you instant and accurate
            information. Download the Cross Tails app from the Apple App Store
            or Google Play Store to save tons of time and know right away what
            you can give to your beloved fur baby.
          </p>
        </div>
        <div className={styles.videoContainer}>
          <ReactPlayer
            url="/videos/CT-Active.mp4"
            height="20%"
            width="50%"
            controls={true}
          />
        </div>

        <div className={styles.three} id="download">
          <div className={styles.download}>
            <h1 className={styles.downloadHeader}>Download Our App</h1>
            <div>
              <IconContext.Provider
                value={{ size: "5rem", style: { margin: "2rem" } }}
              >
                <a
                  className={styles.android}
                  href="https://play.google.com/store/apps/details?id=com.crosstails.android"
                  rel="noreferrer"
                  target="_blank"
                >
                  <FaGooglePlay />
                </a>
                <a
                  className={styles.ios}
                  href="https://apps.apple.com/us/app/cross-tails/id6478471119"
                  rel="noreferrer"
                  target="_blank"
                >
                  <IoLogoAppleAppstore />
                </a>
              </IconContext.Provider>
            </div>
          </div>
          <div className={styles.socialMedia}>
            <IconContext.Provider
              value={{
                size: "3rem",
                style: { margin: "2rem", marginBottom: "0" },
              }}
            >
              <a
                className={styles.socialMediaIcon}
                href="https://www.facebook.com/profile.php?id=61552490500901"
                rel="noreferrer"
                target="_blank"
              >
                <FaFacebookF />
              </a>
              <a
                className={styles.socialMediaIcon}
                href="https://vm.tiktok.com/ZTLrKPkHX/"
                rel="noreferrer"
                target="_blank"
              >
                <FaTiktok />
              </a>
              <a
                className={styles.socialMediaIcon}
                href="https://www.instagram.com/crosstailsnation/"
                rel="noreferrer"
                target="_blank"
              >
                <FaInstagram />
              </a>
              <a
                className={styles.socialMediaIcon}
                href="https://www.youtube.com/channel/UCjwSBpoU7nKRLEa33jhk2Wg"
                rel="noreferrer"
                target="_blank"
              >
                {" "}
                <FaYoutube />
              </a>
              <a
                className={styles.socialMediaIcon}
                href="https://t.snapchat.com/XScKegwx"
                rel="noreferrer"
                target="_blank"
              >
                <FaSnapchatGhost />
              </a>
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
