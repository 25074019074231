import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Home from './screens/home/Home';
import RootLayout from "./screens/rootLayout/RootLayout";
import Privacy from './screens/privacyPolicy/Privacy';
import ErrorPage from './screens/errorPage/ErrorPage';
import Terms from './screens/terms/Terms';
import Disclaimer from './screens/disclaimer/Disclaimer';


const router = createBrowserRouter([
  {
    path:"/",
    element:<RootLayout/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      { path: "/privacy", element: <Privacy/> },
      { path: "/terms", element: <Terms/> },
      { path: "/disclaimer", element: <Disclaimer/> },

    ],
  }
]);

function App() {
  return  <RouterProvider router={router}/>;
};

export default App;
