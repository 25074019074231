import { Link } from "react-router-dom";
import styles from "./Disclaimer.module.css";
import logo from "../../assets/images/crosstailsroundlogo.png";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Disclaimer = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.container}>
      <div>
        <Link to="/">
          <img src={logo} className={styles.logo} alt="Crosstails Logo" />
        </Link>
      </div>
      <h1 className={styles.header}>Disclaimer</h1>
      <div className={styles.infoContainer}>
        <p className={styles.info}>
          Thank you for visiting{" "}
          <Link to="/" className={styles.websiteLink}>
            <span className={styles.webTextLink}>www.crosstails.com</span>
          </Link>{" "}
          (the "Website") and Cross Tails App (the "App").<br></br>
        </p>
        <p className={styles.info}>
          (Cross Tails LLC), along with its member(s), manager(s), officers,
          directors, employees, subsidiaries, affiliates, licensors, service
          providers, and agents, including (Giavanna Hampton) (hereinafter
          collectively the “Company”, “We” or “Us”) are NOT veterinarians, and
          nothing contained on the Website, App, or part of any membership
          program on the Website is intended to be veterinary advice. We can not
          and do not make any guarantees about your animal’s results and we are
          not reliable for the health of your animal from our information,
          tools, or strategies. What we can guarantee is your satisfaction with
          our application, services, and products.
          <br />
        </p>

        <p className={styles.info}>
          You should know that all products and services by our company are for
          informational purposes only. Besides the clothing apparel (coming in
          near future), the food search is based on research reviewed by
          veterinarians, expert animal trainers and animal dieticians, and from
          professionals with Veterinarian medical degrees. Nothing on this page,
          any of our websites, or any of our content, applications, or tools is
          a promise or guarantee of pet results, and we do not offer any
          medical, dietary, veterinary, or other professional advice. <br />
        </p>

        <p className={styles.info}>
          Use caution and always consult your veterinarian, before acting on any
          information related to an animal's lifestyle and dietary change. Be
          extra cautious with dietary and lifestyle changes to animals who are
          older in age or have a medical condition. You alone are responsible
          and accountable for your decisions, actions, and results in your
          animal's life, and by your registration here, you agree not to attempt
          to hold Cross Tails liable for your decisions, actions or results, at
          any time, under any circumstance. <br />
        </p>
        <p className={styles.info}>
          Our safety results of food items are approved by animal health
          professionals. While most individual ingredients have been vetted, the
          safety of some meal combinations may be inferred based on the known
          safety of their constituent ingredients. Always consult with a
          veterinarian if you have specific concerns about your pet’s diet.
        </p>

        <p className={styles.info}>
          The Company may derive fees or other compensation as a result of
          purchases from service providers or vendors that are recommended from
          the Website and/or membership program. <br />
        </p>
      </div>
    </div>
  );
};
export default Disclaimer;
